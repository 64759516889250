import React, { FC, useState, useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Rating from '@material-ui/lab/Rating';
import * as Yup from 'yup';
import axios from 'src/utils/axios';
import './stylesglobalrating.css';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { Field, Form, Formik, FormikHelpers, useField } from 'formik';

interface Props {
    className?: any;
    type?: any;
    item_id?: any;
}

const GlobalReviewSection: FC<Props> = ({ className, type, item_id, ...rest }) => {
    console.log(item_id);
    const [value, setValue] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const { user, isAuthenticated } = useAuth();
    const [isFetching, setIsFetching] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = useState(1);
    const [HasMore, setHasMore] = useState(true);
    const [newsListing, setNewsListing] = useState([]);
    const isMountedRef = useIsMountedRef();
    const getNewsData = useCallback(async (prevPageNumber, newsListing) => {
        setIsFetching(true);
        try {
            const params = {}
            params['page'] = prevPageNumber;
            params['item_id'] = item_id

            params['size'] = '3';
            params['columnname'] = 'created_at';
            params['orderby'] = 'desc';
            params['status'] = '1';
            if (type == "school") {
                params['type'] = type

            }

            const response = await axios.get('api/admin/review/get', { params: params });
            if (isMountedRef.current) {
                setTotalPages(response.data.totalPages);
                setPage(prevPageNumber);
                setHasMore(response.data.data.length > 0);
                setNewsListing([...newsListing, ...response.data.data]);
                setIsFetching(false);
                setTotalItems(response.data.totalItems);
                // setPage((prevPageNumber) => prevPageNumber + 1);

            }
        } catch (err) {
            // console.error(err);
        }
    }, [isMountedRef]);

    useEffect(() => {

        getNewsData(page, newsListing);

    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    mobile: '',
                    rating: 0,
                    content: '',
                }}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.mixed().required('Name  is Required'),
                        email: Yup.mixed().required('Email  is Required'),
                        mobile: Yup.mixed().required('Mobile Number is Required'),
                        rating: Yup.number().integer().min(1).required('Rating  is Required'),
                    })
                }
                onSubmit={async (values, actions) => {
                    const formData = new FormData();

                    var ratings = values.rating.toString();
                    formData.append('name', values.name);
                    formData.append('email', values.email);
                    formData.append('mobile', values.mobile);
                    formData.append('userrating', ratings);
                    formData.append('content', values.content);
                    formData.append('is_approved', '0');
                    formData.append('item_id', item_id);
                    formData.append('type', type);
                    if(user && user.id ){
                        formData.append('user_id', user.id);
                    }
                   //'5'

                    let url = 'api/admin/review/add';

                    try {
                        let response = await axios.post(url, formData);

                        if (response.data.status) {
                            actions.setSubmitting(true);
                            actions.resetForm({
                                values: {
                                    rating: 0,
                                    name: '',
                                    email: '',
                                    mobile: '',
                                    content: ''
                                },
                            });
                            enqueueSnackbar('Thankyou for submit review', {
                                variant: 'success',
                            });
                        }
                    } catch (err) {
                        console.error(err);
                        enqueueSnackbar("Please try again", {
                            variant: 'error',

                        });
                    }
                }}
            >
                {props => (
                    <>
                       
                            <form onSubmit={props.handleSubmit}>
                                <section className="write-reviews container-fluid spac_01 " id="reviewssection" data-aos="fade-up">
                                    <div className="container">
                                        <div className="row">
                                            <h3 className="main-heading">Write a <b>Review</b></h3>
                                            <div className="spac_02"></div>

                                            <div className="row">
                                                <div className="col-lg-6 form-group">
                                                    <label>Name</label>
                                                    <Field type="text" name="name"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.name}
                                                        className="form-control" id="name" placeholder="Enter Name" />
                                                    {props.errors.name && props.touched.name && <p className="error">{props.errors.name}</p>}
                                                </div>
                                                <div className="col-lg-6 form-group">
                                                    <label>Email</label>
                                                    <Field type="email" name="email"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.email}
                                                        className="form-control" id="email" placeholder="Enter Your Email" />
                                                    {props.errors.email && props.touched.email && <p className="error">{props.errors.email}</p>}
                                                </div>
                                                <div className="col-lg-6 form-group">
                                                    <label>Mobile Number</label>
                                                    <Field type="text" name="mobile"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.mobile}
                                                        className="form-control" id="mobile" placeholder="Enter Your Mobile" />
                                                    {props.errors.mobile && props.touched.mobile && <p className="error">{props.errors.mobile}</p>}
                                                </div>
                                                <div className="col-lg-6 form-group">
                                                    <div className="form-group" id="rating-ability-wrapper">
                                                        <label className="bold rating-header">
                                                            &nbsp;
                                                        </label>
                                                        <div className="spac_05"></div>
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            <label className="bold rating-header">
                                                                Rating
                                                            </label>
                                                            <Rating
                                                                name="rating"
                                                                value={props.values.rating}
                                                                onChange={(event, newValue) => {
                                                                    props.setFieldValue('rating', newValue);
                                                                }}
                                                            />

                                                            <label className="bold rating-header" style={{ marginLeft: '20px' }}>
                                                                <span className="selected-rating">{value}</span><small> / 5</small>
                                                            </label>
                                                        </div>
                                                        {props.errors.rating && props.touched.rating && <p className="error">{props.errors.rating}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group">
                                                    <label>Description</label>
                                                    <Field className="form-control" as="textarea" name="content" placeholder="Write Your Reviews">
                                                        {props.values.content}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="text-center"><button type="submit">Submit Your Review</button></div>
                                        </div>
                                    </div>
                                </section>
                            </form>
                            {/* :
                            <section className="write-reviews container-fluid spac_01 " id="reviewssection" data-aos="fade-up">
                                <div className="container">
                                    <div className="row">
                                        <h3 className="main-heading">Please <a href="/login">login</a> to Write a <b>Review</b></h3>
                                    </div>
                                </div>
                            </section> */}
                        
                    </>
                )}
            </Formik>

            <div className="col-lg-12 col-md-12 top-colleges testiSec" >
                {
                    newsListing && newsListing.map((i) => {

                        return (
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="testi_wrp">


                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp testimnl">
                                        <div className="test_content_wrp">
                                            {i.userrating > 0 ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {i.userrating}</div> : ''}
                                            <p>{i.content}</p>
                                            <a href="#" className="testi_user">
                                                <img alt={i?.users?.name} src={process.env.REACT_APP_IMG_URI + 'website_image/avtar.png'} className="testi_photo" /><span><h3>{i.name}</h3></span>
                                            </a>
                                            <div className="clearfix"></div>
                                        </div>
                                        <img src={require("src/assets/img/l_quote.svg")} className="lquote" />
                                        <img src={require("src/assets/img/r_quote.svg")} className="rquote" />
                                    </div>
                                </div>






                            </div>
                        )
                    })
                }
                {newsListing.length < 1 ? (
                    ''
                ) : ''}

                {totalItems > 3 ? (
                    <>
                        <div className='clearfix'></div>
                        <div className="spac_02"></div>
                        <div className="button-section text-center">
                            {isFetching && <p>Loading more data...</p>}
                            {!isFetching && HasMore && (
                                <button className="button btn"

                                    onClick={() => getNewsData(page + 1, newsListing)}
                                >Load more</button>
                            )}
                        </div>
                        <div className="spac_02"></div>
                    </>
                ) : ''}
            </div>
        </>
    );
};

export default GlobalReviewSection;